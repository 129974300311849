html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}
  
* {
  box-sizing: border-box;
}


.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rs-calendar-table-cell-content {
  line-height: 2.9 !important;
}

.rs-calendar-tooltip {
  display: none !important;
}


.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  border-radius: 50% !important;
  color: #fff5f5 !important;
  height: 35px !important;
  width: 35px !important;
  line-height: 2.8 !important;
  text-align: center !important;
  z-index: 0 !important;
}


.rs-calendar-popover {
  display: none !important;
}


.rs-btn-icon.rs-btn-sm>.rs-icon {
  display: none;

}

.rs-btn-sm.rs-btn {

  cursor: not-allowed;
  pointer-events: none;
}

.rs-btn-icon.rs-btn-sm {
  pointer-events: none;
}

.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  display: none;
}


.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month {
  pointer-events: none;
  cursor: default;
}